<template>
<div class="px-2 py-10 mx-auto wrapper md:px-8 xl:px-0">
  <slider class="max-w-6xl mx-auto feature-slider" v-bind="settings" @afterChange="handleChange">
    <feature-slide v-for="s in slides" :key="s.index" :fields="s.fields" />
    <template #prevArrow>
      <button class="backward purple pulse">Prev</button>
    </template>
    <template #nextArrow>
      <div class="pulse"><button class="forward purple">Next</button></div>
    </template>
  </slider>
</div>
</template>

<script>
import Slider from 'vue-slick-carousel'
import FeatureSlide from './FeatureSlide'

export default {
  components: {
    Slider,
    FeatureSlide
  },
  props: [
    'content'
  ],
  data: function() {
    return {
      settings: {
        arrows: true,
        dots: true,
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 7000,
      }
    }
  },
  methods: {
    handleChange() {
      for(var i = 0; i < document.querySelectorAll('.slick-slide a').length; i++) {
        let x = document.querySelectorAll('.slick-slide a')[i].getBoundingClientRect().x
        if(x < window.innerWidth && x > 0) {
          document.querySelectorAll('.slick-slide a')[i].tabIndex = 0
        } else {
          document.querySelectorAll('.slick-slide a')[i].tabIndex = -1
        }
      }
    }
  },
  computed: {
    slides() {
      let slides = []
      this.content.children.map(s => {
        if(s.fields) {
          slides.push(s)
        } else {
          console.error('Slide not properly formatted', s)
        }
      })
      return slides
    }
  }
}
</script>

<style lang="scss">
.cowboys-slide {
  .feature-slide-text {
    background: #00A3DA;
  }
}
// .feature-slider {
//   .slick-arrow {
//     position: absolute;
//     bottom: .4rem;
//     left: 0;
//     width: 6rem;
//     z-index: 20;

//     &.slick-next {
//       left: 7rem;
//     }
//   }
//   .slick-dots {
//     width: auto;
//     right: 0;
//     top: 0;
//     bottom: auto;
//     padding: .75rem 0;

//     li {
//       margin: 0 .5rem .5rem;
//     }

//     button {
//       width: .75rem;
//       height: .75rem;
//       border: 1px solid white;
//       background-color: transparent;
//     }
//   }
// }

.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

li.slick-active button {
  background: white!important;
}

.feature-slide-text, .experience-slide-text {
  &.blue {
    background: transparent;
    background-image: url('https://images.ctfassets.net/3dar4x4x74wk/HA3UT4sx7v43UGlrk3F6u/6f130eef2d4374b48f98d525f3dd3ca7/Blue.jpg')!important;
  }
  &.blue-green {
    background: transparent;
    background-image: url('https://images.ctfassets.net/3dar4x4x74wk/3VC09bWcKNg38soxDYwy8d/607ba5c1ef4bebb30a209576e37c6876/BlueGreen.jpg')!important;
  }
  &.blue-green-vertical {
    background: transparent;
    background-image: url('https://images.ctfassets.net/3dar4x4x74wk/76htJN2jCBy7KwEL4KuF8t/e4e100c08d10682e9116b82a3dbb3080/blue-green-vertical.png')!important;
  }
  &.pink-orange {
    background: transparent;
    background-image: url('https://images.ctfassets.net/3dar4x4x74wk/3WbpCThhCB0G7CGEODvnAR/78be9a0c6ef778034896a3717968c587/PinkOrange.jpg')!important;
  }
  &.pink-purple-blue {
    background: transparent;
    background-image: url('https://images.ctfassets.net/3dar4x4x74wk/7hZm6IRJKcY5hwnWcPrt3X/4da2b372d0b02ccee7e916e441b1d07d/PinkPurpleBlue.jpg')!important;
  }
  &.purple {
    background: #B95EFF;
  }
  &.green-holiday {
    background: transparent;
    background-image: url('https://images.ctfassets.net/3dar4x4x74wk/4XHvEe76Eum3rzkfSzu76A/fb1b8e5c9d123398f09e9c0c0391fe26/Green_Holiday.jpg')!important;
  }
  &.holiday-teal {
    background: transparent;
    background-image: url('https://images.ctfassets.net/3dar4x4x74wk/4hayGm54OVUvrnBcOrkboo/8d83585bc373281cfdff355d85e23e4f/Teal_Holiday.jpg') !important;
  }
  &.red-holiday {
    background: transparent;
    background-image: url('https://images.ctfassets.net/3dar4x4x74wk/4DMrPEJrTx7smrWX35y3Zo/c9c53e113525cae0007a473827b0b413/Red_Holiday.jpg')!important;
  }

  &.blue-holiday {
    background: transparent;
    background-image: url('https://images.ctfassets.net/3dar4x4x74wk/5p0spdwAzIeLFWnH1YmUUk/c3f810581fff36aa891e56faa2d72ff5/holiday-blue.jpg')!important;
  }

  &.blue-flat {
    background: transparent;
    background-image: url('https://images.ctfassets.net/3dar4x4x74wk/67Dkc5GpjgJtOKUNwuPtoM/d06197de2455515b8b116559ce7b46ca/blue-flat.jpg')!important;
  }

  &.luck-green {
    background: transparent;
    background-image: url('https://images.ctfassets.net/3dar4x4x74wk/44kpUMYaU4kOyO9xYYgUHj/15805830eb4a6fa13dc5f1c9d7fab497/luck-green.jpg')!important;
  }

  &.cookie-shakes {
    background: transparent;
    background-image: url('https://images.ctfassets.net/3dar4x4x74wk/7auj6ncMr57WpFGLsIHJoo/041906da970cbf44069a9a02ae2e079c/ME_CookieShakes_HomePageCarousel_BG.jpg') !important;
  }

  &.holiday-blue-2023 {
    background: #3233fe;
  }

  &.fk-holiday-teal---sparkles {
    background: #69d6b5;
    background-image: url('https://images.ctfassets.net/3dar4x4x74wk/3QOeDbIfHTJnO5E9573QcE/811e639a0813dbd155f489b64597a56a/ME_HomepageCarousel_Desktop_BoxOverlay_520x520_v2.jpg')!important;
    background-size: contain!important;
  }

  &.rainbow-gradient {
    background: transparent;
    background-image: url('https://images.ctfassets.net/3dar4x4x74wk/1nzsQ6ujatPsCJ7S6L4pql/4723749ee3b9433e81d2074614607b28/ME_SSP_HomepageCarousel_Box-rainbow-gradient.jpg') !important;
  }

  .high-contrast & {
    background-image: none !important;
  }

  &.red-holiday-snowflakes {
    background: transparent;
    background-image: url('https://images.ctfassets.net/3dar4x4x74wk/4JS0YZsJubrmRvWpbktHZp/c034f305d62ae63de2ee2924887ccb14/Box_with_Snowflakes.png');
  }

  &.baby-blue {
    background: transparent;
    background-image: url('https://images.ctfassets.net/3dar4x4x74wk/2ouFjjB9QJrCGmQnJy1Ijd/831a56202ca8a1cec6b656a5009fdb95/Homepage_Carousel_Box_Overlay.jpg');
  }

  &.pink {
    background: transparent;
    background-image: url('https://images.ctfassets.net/3dar4x4x74wk/2GUjW0cQWHevgKw9oW3dk3/65d6c6aac148bcd5edd79c3a29403a73/Homepage_Carousel_Box_Overlay.jpg');
  }
}
</style>
